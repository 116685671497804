<template>
  <div class="row h-100 login-wrap">
    <div class="hidden-md col-md-5 right-login">
      <div class="wrap-content">
        <div class="subtitle-welcome">
          <img src="/static/images/login/titulo.png" alt="">
          {{ $t('login.content.subtitle') }}
        </div>
        <div class="content-welcome">
          <p>
            {{ $t('login.content.first') }}
            {{ $t('login.content.hightlight') }}
            {{ $t('login.content.last') }}
          </p>
          <a target="_blank" href="https://royalcargo.com.br/blog/2021/06/14/portal-web-da-royal-cargo-informacao-em-um-unico-lugar/">
            {{ $t('login.content.link') }} >>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-7 left-login">
      <div class="auth-content col-md-11">
        <div class="card-body">
          <div class="fast-access">
            <router-link :to="{name: 'Login'}">
              ACESSO eRoyal
            </router-link>
          </div>
          <div class="card-title text-center mb-5 font-18">
            <div class="text-center subtitle">
              <img src="/static/images/login/logo.png" alt="logo" class="logo">
              <span>{{ $t('login.title1') }}</span> {{ $t('login.title2') }}
            </div>
          </div>
          <div class="text-center label subtitle-content">
            {{ $t('tools-network.title') }}<br/>
            {{ $t('tools-network.subtitle') }}
          </div>
          <div class="diagnostico">
            <div>
              <div class="diagnostic-section mt-5" v-bind:class="{'error': error}">
                <i v-if="success" class="font-20 fa fa-check-circle mt-1"></i>
                <i v-if="error" class="font-20 fa fa-exclamation-circle mt-1"></i>
                <i v-if="isLoading" class="font-20">
                  <svg v-if="isLoading" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width="21" height="21" stroke="#000"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform></path></g></g></svg>
                </i>
                <span class="diagnostic-section-title">Firewall/Proxy</span>
              </div>
              <div>
                <p class="mb-2 mt-3">Para proteger sua rede contra possíveis ataques e várias ameaças presentes na internet, algumas empresas adotam o uso de firewall e/ou proxys</p>
                <p class="mb-2" v-if="isLoading">
                  Estamos verificando suas configurações de rede, esse processo pode demorar um pouco.
                </p>
                <p class="mb-3" v-if="success">Aparentemente tudo está funcionando.</p>
                <p class="mb-3" v-if="success">Se o problema persistir, entre em contato com nossa equipe.</p>
                <p class="mb-3" v-if="error">Algo está errado!</p>
                <p class="mb-3" v-if="error">
                  Não conseguimos acessar o endereço https://portal.royalcargo.com.br:82
                </p>
                <p class="mb-3"  v-if="error">
                  Solicite para o responsável pelo gerenciamento de sua rede, geralmente o setor de TI, para adicionar na lista de endereços/sites permitidos e confiáveis.
                </p>
              </div>
              <div class="mb-5 text-center">
                <button v-if="first" class="btn btn-primary btn-padding" type="button" v-on:click="beginDiagnostic" >{{ $t('tools-network.begin') }}</button>
                <button v-else class="btn btn-primary btn-padding" type="button" v-on:click="beginDiagnostic" >{{ $t('tools-network.restart') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Form

// import router from '@/router'
import BaseService from '@/services/BaseService'

import './login.css'
import '@/assets/css/custom.css'

export default {
  name: 'Network-check',
  metaInfo: {
    titleTemplate: '%s | Teste Conexão'
  },
  components: {
  },
  data () {
    return {
      success: null,
      error: null,
      first: true,
      isLoading: false,
      loading: false
    }
  },
  methods: {
    checkFirewallProxy () {
      return BaseService.checkAccess()
    },
    beginDiagnostic () {
      this.error = null
      this.success = null

      this.first = false
      this.isLoading = true
      setTimeout(() => {
        this.checkFirewallProxy().then((res) => {
          if (res.data.code === 201 && res.data.message === 'Sucesso') {
            this.success = true
          }
        }).catch(() => {
          this.error = true
        }).finally(() => {
          this.isLoading = false
        })
      }, 2000)
    }
  }
}
</script>

<style scoped>
  .diagnostic-section {
    background-color: #d1b178;
    padding: 16px 24px;
    font-size: 16px;
    color: #393939;
    font-weight: 600;
  }

  .diagnostic-section-title {
    line-height: 21px;
    padding-left: 10px;
  }

  .diagnostico p {
    color: #495057;
  }

  .diagnostic-section.error {
    color: #ff1744;
  }
  .fast-access {
    position: absolute;
    right: 0;
    top: 10px;
    display: inline-block;
  }

  .fast-access a {
    color: #8c6d38 !important;
    padding: 10px;
    font-weight: normal;
  }

  .login-wrap .left-login form span {
    font-size: 0.95em;
  }

  .login-wrap .left-login .subtitle-content {
   max-width: 450px;
   margin: auto;
  }

  .content-welcome a {
    background-color: inherit;
    color: #d1b178 !important;
    padding-left: 0;
  }

  .content-welcome a:hover {
    color: #8c6d38 !important;
  }

  .auth-content a {
    color: #d1b178 !important;
  }

  .auth-content a:hover {
    color: #8c6d38 !important;
  }
</style>
